import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ButtonLink from "../components/ButtonLink"
import Grid from "../components/grid/Grid"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const TextAndLink = ({ title = "", children, buttonText, to = "/" }) => (
  <div className="col-span-full md:col-span-7 lg:col-span-5 pt-10 flex flex-col">
    <h2>{title}</h2>
    <section className="pt-8 md:pt-10">
      <div className="grid gap-y-5 md:gap-y-8">{children}</div>
    </section>

    <div className="flex w-full  justify-start mt-10 ">
      <ButtonLink text={buttonText} to={to} />
    </div>
  </div>
)

const SubTitle = ({ children }) => <h4 className="font-semibold">{children}</h4>

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO />

      <Grid>
        <div className="col-span-5 md:col-span-6 lg:col-span-5 lg:col-start-2 ">
          <h4 className="font-semibold">Physiotherapy and Pilates Studio</h4>
          <h1 className="my-5">
            Live life to the fullest through better movement
          </h1>
          <p className="font-normal">
            We help you to take charge of your body and thrive
          </p>
        </div>
        <div className=" relative col-span-5 md:col-span-7 lg:col-span-6 col-start-2 md:col-start-6 grid justify-items-end ">
          <div className="relative">
            <GatsbyImage
              image={getImage(data.photoOne)}
              className="relative mt-10  z-20"
              imgClassName="rounded-2xl"
            />
            <div className="absolute bottom-0 -left-20 md:left-0 right-0">
              <GatsbyImage
                className="w-56 md:w-96  md:-ml-24 -mr-14 -my-20  -mb-32 md:-mb-56  z-0 transform  -rotate-25"
                image={getImage(data.brushstroke)}
                alt="Two people stretching"
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid className=" mt-20">
        <div className="hidden lg:block col-span-1"></div>
        <div className="col-span-4 md:col-span-5 lg:col-span-4 ">
          <GatsbyImage
            image={getImage(data.photoTwo)}
            className="w-full z-1"
            imgClassName="rounded-2xl"
            alt="A physiotherapist treating a client"
          />
        </div>
        <div className="hidden lg:block col-span-1"></div>

        <TextAndLink
          title="Discover a holistic approach to enhancing your mobility "
          buttonText={
            <span>
              <span className="hidden sm:inline">
                Learn more about our services
              </span>
              <span className="inline md:hidden">Our services</span>
            </span>
          }
          to="/services"
        >
          <p>
            Our team works to balance the art of human connection with the
            science of restoring, maintaining, and improving mobility, function,
            and well-being.
          </p>
        </TextAndLink>
      </Grid>
      <Grid className=" mt-20">
        <div className="hidden lg:block col-span-1"></div>
        <div className="col-span-4 md:col-span-5 lg:col-span-4 block md:hidden ">
          <GatsbyImage
            image={getImage(data.photoThree)}
            className=" w-full z-1"
            imgClassName="rounded-2xl"
            alt="A massage therapist treating a client."
          />
        </div>
        <TextAndLink
          title="Empower yourself with the tools for more mindful movement"
          buttonText="Book a free consultation"
          to="https://artandsciencephysio.janeapp.com/#/staff_member/1/treatment/8"
        >
          <p>
            We take the time to listen to your needs and provide a personalized
            treatment plan that includes the support, tools and resources you
            need to achieve better movement.
          </p>

          <p>
            We do more than just fix your injuries, we arm you with the tools
            you need to improve your movement from head to toe. We’re focused on
            building trust with you to improve your mobility for the long term.
          </p>

          <p>
            You want to feel seen and be heard. We take your pain seriously and
            are committed to providing a personalized and hands-on approach to
            all of our services. We’ll work with you to create a custom
            treatment plan using therapeutic movement, pain control strategies
            and exercise prescription to help you meet your goals.
          </p>
        </TextAndLink>
        <div className="hidden lg:block col-span-1"></div>
        <div className="col-span-4 md:col-span-5 lg:col-span-4 hidden md:block ">
          <GatsbyImage
            image={getImage(data.photoThree)}
            className=" w-full z-1"
            imgClassName="rounded-2xl"
            alt=""
          />
        </div>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    photoOne: file(relativePath: { eq: "2022/007A5772.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 75, width: 650)
      }
    }
    photoTwo: file(relativePath: { eq: "2022/007A5879-2.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 432
          transformOptions: { cropFocus: ATTENTION }
        )
      }
    }
    photoThree: file(relativePath: { eq: "2022/007A5413.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 432
          aspectRatio: 0.7
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    brushstroke: file(relativePath: { eq: "brush.png" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 432
          height: 545
          aspectRatio: 0.7
        )
      }
    }
  }
`

export default IndexPage
